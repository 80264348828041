import { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { Box } from '@mui/material';
import VideoPlayer from '../../components/VideoPlayer';
import video from '../../../assets/video/rooftop-solfin.mp4';
import videoSvgImage from '../../../assets/images/video-cover.jpg';

const Feature = ({ icon, title, bgClass }) => {
  return (
    <div
      className={`feature h-100`}
      uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 500;"
    >
      <div
        className={`feature-wrap ${bgClass} pt-4 pb-4 pe-4 ps-4 h-100 feature-hover`}
      >
        <div className="feature-icon d-flex align-items-center">
          <img src={icon} alt={title} title={title} />
        </div>
        <div className="feature-text mt-4">
          <h6 className="fw-bold heading-color">{title}</h6>
        </div>
      </div>
    </div>
  );
};

const WhatWeDoLeft = () => {
  return (
    <div className="what-we-do-left">
      <div className="solfin-heading">
        <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
          WHAT WE DO
        </div>
        <h2 className="fw-bold heading-color">
          Let's <span className="text-theme-green">Build</span> our Sustainable
          Future
        </h2>
      </div>
      <div className="text-para mt-4 pb-lg-1 pe-lg-5">
        <p>
          Solfin is building a state-of-the-art end-to-end platform to deliver
          best-in-class solar projects through a network of trusted EPC partners
          and in-house financing/leasing arm.
        </p>
      </div>
    </div>
  );
};

const WhatWeDoRight = () => {
  const features = [
    {
      icon: '/images/energy-solutions.svg',
      title: 'Trusted EPC partners',
      bgClass: 'theme-green-bg'
    },
    {
      icon: '/images/end-to-end-service.svg',
      title: 'End-to-end Services',
      bgClass: 'bs-body-bg'
    },
    {
      icon: '/images/end-to-end-service.svg',
      title: 'Lightening financing per your needs',
      bgClass: 'bs-body-bg'
    },
    {
      icon: '/images/energy-solutions.svg',
      title: 'Best in Class Solar Panels backed by Manufacturer Warranty',
      bgClass: 'theme-green-bg'
    }
  ];

  return (
    <div className="what-we-do-right">
      <div className="our-features d-flex flex-wrap">
        <div className="row g-3">
          {features.map((feature, index) => (
            <div className="col-md-6" key={index}>
              <Feature
                icon={feature.icon}
                title={feature.title}
                bgClass={feature.bgClass}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const WhatSolfinDo = () => {
  return (
    <section>
      <div className="what-solfin-do bg-white section-padding">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <WhatWeDoLeft />
            </div>
            <div className="col-lg-6">
              <WhatWeDoRight />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const steps = [
  {
    title: 'System Design',
    text: 'EPC would conduct a site visit and design a system',
    icon: '/images/solar-panel.svg',
    alt: 'System Design'
  },
  {
    title: 'Consultation',
    text: 'Discuss with EPC, the project in detail on Kwh requirement, Net Metering, Storage etc.',
    icon: '/images/consultation.svg',
    alt: 'Consultation'
  },
  {
    title: 'Flexible Financing',
    text: 'Choose best finance options basis interest rate, tenure, via state of art Digital loan processing system',
    icon: '/images/payment-plan.svg',
    alt: 'Payment Plan'
  },
  {
    title: 'Installation',
    text: 'Customer can get the project installed after closure of EPC and Financing contracts.',
    icon: '/images/installation.svg',
    alt: 'Installation'
  },
  {
    title: 'Monitoring & Maintenance',
    text: 'Low cost maintenance through EPC and special maintenance partners',
    icon: '/images/monitoring-maintance.svg',
    alt: 'Monitoring & Maintenance'
  },
  {
    title: 'Plant Optimization & Expansion',
    text: 'Storage Options and utilization options',
    icon: '/images/battery-optimization.svg',
    alt: 'Battery Optimization'
  }
];

const Step = ({ title, text, icon, alt }) => (
  <li className="solfin-step completed">
    <div className="step w-100">
      <div className="step-title">
        <h6 className="text-white fw-bold">{title}</h6>
      </div>
      <div className="step-text">
        <p>{text}</p>
      </div>
    </div>
    <div className="step-icon d-flex align-items-center justify-content-center rounded-circle">
      <span className="w-100 h-100 d-flex align-items-center justify-content-center rounded-circle">
        <img src={icon} alt={alt} title={title} />
      </span>
    </div>
  </li>
);

const SimpleSteps = () => {
  return (
    <section>
      <div className="how-we-work section-padding">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="solfin-heading">
                <div className="small-heading mb-3 fw-semibold position-relative text-uppercase text-white">
                  how it works
                </div>
                <h2 className="fw-bold heading-color text-white">
                  A few <span className="text-theme-green">simple</span> steps
                </h2>
              </div>
            </div>

            <div className="col-12">
              <div className="solfin-simple-steps">
                <ul className="timeline list-unstyled m-0 position-relative text-white d-flex">
                  {steps.map((step, index) => (
                    <Step
                      key={index}
                      title={step.title}
                      text={step.text}
                      icon={step.icon}
                      alt={step.alt}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Register = () => {
  const [videoSrc, setVideoSrc] = useState(null);

  useEffect(() => {
    const worker = new Worker(
      new URL('../../../utils/resourceUrlWorker', import.meta.url)
    );
    worker.postMessage(video);
    worker.onmessage = function (e) {
      setVideoSrc(e.data);
    };

    return () => worker.terminate();
  }, []);

  return (
    <>
      <section>
        <div className="solfin-video position-relative overflow-hidden">
          <div className="video-container">
            {videoSrc ? (
              <VideoPlayer
                src={videoSrc}
                poster={videoSvgImage}
                className="position-absolute start-0 top-0"
                autoPlay={true}
                muted={true}
                loop={true}
                preload="auto"
              />
            ) : (
              <Box className="position-relative">
                <img
                  className="position-absolute"
                  style={{ marginTop: '9rem', aspectRatio: 1 }}
                  src={videoSvgImage}
                />
              </Box>
            )}
            <div className="overlay h-100"></div>
            <div className="solfin-caption position-relative z-3 d-flex align-items-center justify-content-center flex-wrap pb-lg-5 ">
              <div className="caption-text text-center">
                <div className="hero-heading">
                  <h1 className="text-white fw-bold m-0">
                    <span className="text-theme-green animate__animated animate__bounce">
                      <span className="text-white fw-bold m-0 px-2">Your</span>
                      trusted
                      <span className="text-white fw-bold m-0 px-2">
                        partner for a
                      </span>
                      sustainable
                      <span className="text-white fw-bold m-0 px-2">
                        future...{' '}
                      </span>
                    </span>
                    <span className="text-theme-green px-2">
                      Go Solar, Go Green.
                    </span>
                  </h1>
                </div>
              </div>
              <div className="container custom-container">
                <div
                  className={`calculate-saving calculatebtn-move d-flex align-items-center justify-content-between rounded-20 ps-xl-5 ps-lg-4 ps-3 pe-xl-5 pe-lg-4 pe-3 pt-md-4 pt-3 pb-md-4 pb-3 mb-4`}
                >
                  <div className="left-text-calculative text-white w-100">
                    <h5 className="fw-bold text-white"> Solar Roof Top </h5>{' '}
                    <h5 className=" text-white">
                      {' '}
                      Commercial, Industrial, Residential, Greenfield
                    </h5>
                    <p className="mb-0 mt-md-3 mt-2 mb-md-0 mb-3 fw-medium d-none">
                      Roof Top, Greenfield, Residential, Commercial, Industrial
                    </p>
                  </div>
                  {/* <!-- /left text --> */}
                  <div className="right-action-btn mt-2 mt-md-0">
                    <NavLink
                      to="/Estimator"
                      className="btn btn-primary text-uppercase calculate-btn fw-semibold rounded-5 text-nowrap"
                    >
                      <span>Calculate my savings</span>
                      <i className="fa fa-calculator"></i>
                    </NavLink>
                  </div>
                  {/* <!-- /right action --> */}
                </div>
              </div>
              {/* <!-- /calculate-saving --> */}
            </div>
          </div>
        </div>
      </section>

      <WhatSolfinDo />

      <SimpleSteps />

      <section>
        <div className="go-solar-with-solfin overflow-hidden">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="row">
                <div className="left-solar-panel">
                  <div
                    id="carouselExampleDark"
                    className="carousel slide overflow-hidden"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide-to="0"
                        className="active rounded-circle bg-transparent"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide-to="1"
                        className="rounded-circle bg-transparent"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide-to="2"
                        className="rounded-circle bg-transparent"
                        aria-label="Slide 3"
                      ></button>
                    </div>
                    {/* <!-- /carousel-indicators --> */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src="/images/solar-panel-happy-family.jpg"
                          className="d-block w-100"
                          alt="Go Solar with Solfin"
                          title="Go Solar with Solfin"
                        />
                      </div>
                      {/* <!-- /carousel-item --> */}
                      <div className="carousel-item">
                        <img
                          src="/images/solar-panel-happy-mother-daughter.jpg"
                          className="d-block w-100"
                          alt="Go Solar with Solfin"
                          title="Go Solar with Solfin"
                        />
                      </div>
                      {/* <!-- /carousel-item --> */}
                      <div className="carousel-item">
                        <img
                          src="/images/solar-panel-happy-family.jpg"
                          className="d-block w-100"
                          alt="Go Solar with Solfin"
                          title="Go Solar with Solfin"
                        />
                      </div>
                      {/* <!-- /carousel-item --> */}
                    </div>
                    {/* <!-- /carousel-inner --> */}
                  </div>
                  {/* <!-- /carousel --> */}
                </div>
                {/* <!-- /left-solar --> */}
              </div>
            </div>
            {/* <!-- /col-6 --> */}

            <div className="col-lg-6">
              <div className="right-rooftop-area overflow-hidden">
                <h2 className="fw-bold">Go Green with Solfin</h2>
                <div className="right-rooftop-text mt-xl-4 pt-xl-3 pt-2">
                  <h6 className="fw-bold">
                    Creating a greener and better tomorrow
                  </h6>
                  <p>Click to get a Solar roof top installed and financed</p>
                </div>
                {/* <!-- /right-rooftop-text --> */}
                <div
                  className="solfin-action mt-xl-5 mt-4"
                  uk-scrollspy="cls: uk-animation-slide-left-medium; delay: 500;"
                >
                  <Link to="/register" className="text-decoration-none">
                    <span className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-5">
                      Register
                    </span>{' '}
                    <img
                      className="ms-3"
                      src="/images/long-next-arrow.svg"
                      alt="Learn More"
                      title="Learn More"
                    />
                  </Link>
                </div>
                {/* <!-- /solfin-action --> */}
              </div>
              {/* <!-- /right-rooftop-areab --> */}
            </div>
            {/* <!-- /col-6 --> */}
          </div>
        </div>
      </section>

      <section>
        <div className="what-solfin-offers bg-white  section-padding">
          <div className="container custom-container">
            <div className="solfin-offers-wrap">
              <div className="row">
                <div className="col-12">
                  <div
                    className="solfin-heading text-center"
                    uk-scrollspy="cls: uk-animation-fade; delay: 800;"
                  >
                    <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                      SOLFIN EDGE
                    </div>
                    <h2 className="fw-bold heading-color">
                      Building Grounds Up <br />
                      for a{' '}
                      <span className="text-theme-green">Green Future</span>
                    </h2>
                  </div>
                  {/* <!-- /solfin-heading --> */}
                </div>
                {/* <!-- /col-12 --> */}
              </div>
              {/* <!-- /row --> */}

              <div className="we-offers">
                <div className="row align-items-center">
                  <div className="col-lg-4 col-md-6 order-lg-0 order-2">
                    <div className="offers-view">
                      <ul className="list-unstyled m-0 p-0">
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-left-medium; delay: 800;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-plates.svg"
                                alt="Solar Plates"
                                title="Solar Plates"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">End to End Support</h6>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-left-medium; delay: 950;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-water-heat.svg"
                                alt="Solar Water Heater"
                                title="Solar Water Heater"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">
                                Financing Reengineered
                              </h6>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-left-medium; delay: 1100;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-plates.svg"
                                alt="Solar Wind"
                                title="Solar Wind"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">
                                Platform tested & backed products
                              </h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 order-lg-0 order-1">
                    <div
                      className="panel-center-img text-center ps-lg-5 pe-lg-5"
                      uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 800;"
                    >
                      <img
                        src="/images/center-panel.png"
                        className="w-100"
                        alt="Solar Panel"
                        title="Solar Panel"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 order-lg-0 order-3">
                    <div className="offers-view right-side-offer">
                      <ul className="list-unstyled m-0 p-0">
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-right-medium; delay: 800;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-plates.svg"
                                alt="Solar Plates"
                                title="Solar Plates"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">Digital Payments</h6>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-right-medium; delay: 950;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-water-heat.svg"
                                alt="Solar Water Heater"
                                title="Solar Water Heater"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">Super App</h6>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-right-medium; delay: 1100;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-plates.svg"
                                alt="Solar Wind"
                                title="Solar Wind"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">Optimization</h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
