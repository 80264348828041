import { useNavigate } from 'react-router-dom';

export const UI_ROUTES = {
  dashboard: '/',
  login: 'login',
  signup: 'Register',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password/:token',
  finance: 'finance',
  registerCustomer: 'RegisterCustomer',
  premiumPartners: 'PremiumPartners',
  cookiePolicy: 'CookiePolicy',
  EPC: 'Epc',
  DSA: 'Dsa',
  aboutUs: 'Aboutus',
  estimator: 'Estimator',
  mySavings: 'MySavings',
  residentialLoan: 'loan-products/residential',
  knowledge: 'knowledge',
  solfinPrivacyPolicy: 'Privacypolicy',
  dhanvikasPrivacyPolicy: 'DhanvikasPrivacypolicy',
  refundPolicy: 'Refundpolicy',
  dhanvikasFairPracticeCodePolicy: 'FairPracticeCode',
  solfinGrievanceRedressalPolicy: 'GrievanceRedressalPolicy',
  termsAndConditions: 'Termsandconditions',
  distributorJoin: 'distributor/join',
  registrationComplete: 'RegistrationComplete',
  custRegistrationComplete: 'cust-registration-complete',
  custRegistered: 'cust-registered',
  partnerRegistrationComplete: 'PartnerRegistrationComplete',
  lease: 'lease',
  comingSoon: 'coming-soon',
  mission: 'mission',
  contactSales: 'contact-sales',
  leaseContactSales: 'lease-contact-sales',
  admin: 'admin/*',
  NOTFound: '404'
};

export const navigateTo = (route) => useNavigate`/${route}`;
