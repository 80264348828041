import PropTypes from 'prop-types';
import { Modal, Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'min(600px, 90%)',
    height: 'min(424px, 85%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: 'none',
    borderRadius: 2
  },
  header: {
    background: '#000',
    color: '#fff',
    margin: '0rem !important',
    padding: '0.5rem',
    borderTopRightRadius: '0.4rem',
    borderTopLeftRadius: '0.4rem'
  }
};

const CustomModal = ({
  open,
  handleClose,
  headerComponent,
  headerLabel,
  children,
  footerComponent,
  modalMinHeight = '424px'
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...style.modal, height: `min(${modalMinHeight}, 85%)` }}>
        <Box className="modal-header" sx={{ ...style.header }}>
          {headerComponent ? (
            headerComponent
          ) : (
            <Typography sx={{ color: '#fff' }} variant="h6">
              {headerLabel}
            </Typography>
          )}
          <CloseIcon
            sx={{
              cursor: 'pointer',
              color: 'inherit',
              '&:hover': {
                color: '#88F231',
                fontWeight: 'bold'
              }
            }}
            onClick={handleClose}
            color="inherit"
          />
        </Box>
        <Box
          sx={{ height: '68%', overflowX: 'auto' }}
          className="modal-body my-2 p-2"
        >
          {children}
        </Box>
        <Box
          className="d-flex mb-0"
          sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          {footerComponent ? (
            footerComponent
          ) : (
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  headerComponent: PropTypes.element,
  headerLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  footerComponent: PropTypes.element
};

CustomModal.defaultProps = {
  headerComponent: null,
  headerLabel: '',
  footerComponent: null
};

export default CustomModal;
