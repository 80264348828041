import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Montserrat Alternates',
            'Public Sans',
            'sans-serif'
          ].join(','),
          backgroundColor: '#5e5e5ec9'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: ['Public Sans', 'sans-serif'].join(','),
          textTransform: 'capitalize',
          fontSize: '0.8rem'
        }
      }
    }
  },
  typography: {
    fontFamily: [
      'Public Sans',
      'Montserrat Alternates',
      'Montserrat',
      'sans-serif'
    ].join(',')
  }
});

export default theme;
